import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArrowBlack from "../images/right-arrow-black.svg"

import notFound from "../images/404.svg"
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="notfound-page">
      <div className="container-lg">
        <div className="v-center">
          <img src={notFound} alt="404" />
          <div className="text-center">
            <Link to="/">
              <button className="arrow-btn black">
                <span>
                  <img src={ArrowBlack} alt="" />
                </span>
                <span>Take Me Home</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
